/**
* Template Name: Day - v4.7.0
* Template URL: https://bootstrapmade.com/day-multipurpose-html-template-for-free/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Montserrat", sans-serif;
  color: #444444;
}

a {
  color: #cc1616;
  text-decoration: none;
}

a:hover {
  color: #e82d2d;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}


/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #cc1616;
  border-top-color: #efefef;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
/*--------------------------------------------------------------
# Top Bar #191919
 rgba(255, 255, 255, 0.6)
--------------------------------------------------------------*/
#topbar {
  background: rgba(38,66,77,255);
  background: url("../public/pic1.jpg") no-repeat top;
  background-size: cover;
  font-size: 25px;
  height: 40px;
  padding: 0;
  color: black;
}

#topbar .contact-info a {
  line-height: 0;
  color: rgba(214,215,217,255);
  transition: 0.3s;
  margin-left: 5px;
  font-size:  1.4rem;
}
@media screen and (max-width: 494px) {
  #topbar .contact-info a {
    font-size: 1rem;
  }
}
@media screen and (max-width: 368px) {
  #topbar .contact-info a {
    font-size: .7rem;
  }
}
#topbar .contact-info a:hover {
  color: #fff;
}
#topbar .contact-info i {
  color: rgba(214,215,217,255);
  line-height: 0;
  margin-right: 5px;
}
#topbar .contact-info .phone-icon {
  margin-left: 20px;
}
#topbar .social-links a {
  color:  rgba(214,215,217,255);
  padding: 4px 12px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
}
#topbar .social-links a:hover {
  color: #fff;
}

/*--------------------------------------------------------------
# Header
https://unsplash.com/s/photos/real-estate
--------------------------------------------------------------*/
.nav-bar {
  background-color: rgba(214,215,217,255);
  max-height: 245px;

}

.site-branding {
    width: calc(100% - 24px);
    display: inline-block;
    width: 50%;
}

.site-branding a {
    margin-top: 6px;

}

@media screen and (min-width: 992px) {
    .site-branding {
        width: auto;
    }
}

.site-branding .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  color: #212e34;
  text-transform: uppercase;
  position: relative;
  bottom: 275px;

}

.nav-bar img{
    max-height: 350px;
    margin: auto;
    position: relative;
    bottom: 77px;

}


@media screen and (max-width: 992px ) and (min-width: 465px){
  .nav-bar img{
      max-height: 350px;
      bottom: 100px;
      position: relative;
      left:100px;
  }
  .site-branding .logo {
    font-size: 20px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
    color: #212e34;
    text-transform: uppercase;
    position: relative;
    bottom: 270px;

  }

}

@media screen and (max-width: 465px) {
  .nav-bar img{
      max-height: 350px;
      margin: auto;
      position: relative;
      bottom: 100px;
  }
  .site-branding .logo {
    font-size: 20px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
    color: #212e34;
    text-transform: uppercase;
    position: relative;
    bottom: 270px;

  }

}
.site-navigation{
  padding-right: 0px;
  margin-right: 0px;
  padding-top: 0px;
  position: relative;
  bottom: 280px
}

.site-navigation ul {
  position: fixed;
  top: 0;
  left: -320px;
  z-index: 9999;
  width: 320px;
  max-width: calc(100% - 120px);
  height: 100%;
  margin: 0;
  background: rgba(214,215,217,255);
  list-style: none;
  overflow-x: scroll;
  transition: all 0.35s;
}

.site-navigation.show ul {
  left: 0;
}

.site-navigation ul li {
  margin: 0 16px;
  padding-right: 0px;
  margin-right: 0px;
  padding-left: 10px
}

.site-navigation ul li a {
  display: block;
  padding: 16px 0;
  font-size: 17px;
  font-weight: 600;
  line-height: 1;
  color: #212e34;
  text-decoration: none;
  transition: color .35s;
}

.nav-links {
width: 100%;
background-color: white;
color: black;
}
.nav-links ul li {
padding-bottom: 10px;
display: block;
padding: 16px 0;
text-align: center;
font-size: 14px;
font-weight: 600;
line-height: 1;
color: #212e34;
text-decoration: none;
transition: color .35s;
}

.nav-links ul li a {
color: white;
}
.nav-links ul li a:hover {
  border-bottom: 3px solid black;
  color: black;
}

.social {
display: none;
}
@media screen and (max-width: 768px){
.social {
  display: contents;
}

.social a {
color:  rgba(214,215,217,255);
padding: 4px 12px;
display: inline-block;
line-height: 1px;
transition: 0.3s;
font-size: 25px;
}


.social a:hover {
color: #fff;
}}
.okay {
width: 100%;
}
@media screen and (min-width: 992px) {
  .nav-links {
    display: none;
  }
  .site-navigation ul {
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      max-width: 100%;
      height: auto;
      padding: 0;
      overflow: auto;
      background: transparent;
  }
  .site-navigation ul li a {
      padding: 49px 0 30px;
  }
}

.site-navigation ul li a:hover {
  border-bottom: 3px solid white;
  color: white;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}
.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}
@media (max-width: 575px) {
  .section-title h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  .section-title span {
    font-size: 38px;
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/

        .page-header {
            position: relative;
            padding-top: 85px;
            padding-bottom: 42px;
            background-size: cover !important;
        }

        .page-header::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .6);
        }

        .page-header h1 {
            position: relative;
            padding-bottom: 8px;
            font-size: 36px;
            font-weight: 600;
            color: #fff;
            text-shadow: 3px 3px 6px #000;
        }

        .page-header h1::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 64px;
            height: 4px;
            border-radius: 2px;
            background: #207ce5;
        }

        .page-header {
            background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../public/pic.jpeg") bottom;
            background-size: cover;
        }

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
  padding-top: 25px;
}
.about .content ul {
  list-style: none;
  padding: 0;
}
.about .content ul li {
  padding-bottom: 10px;
}
.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #cc1616;
}
.about .content p:last-child {
  margin-bottom: 0;
}


/*.lol {
  background-image: url("../public/pic1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}*/


        .home-page {
            position: relative;
            padding: 96px 0;
            opacity: .9999999;
        }

        .home-page::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background: white;
        }

        .welcome-content .entry-title {
          position: relative;
          font-weight: 600;
          font-size: 26px;
          padding-top: 19px;
          color: black;
        }



        .welcome-content .entry-content p {
            font-size: 16px;
            line-height: 2;
            color: black;
        }
        .welcome-content .entry-content a {
            font-size: 20px;
            line-height: 4;
            color: #6bb0e8;
        }
        .welcome-content .entry-content ul {
            font-size: 16px;
            line-height: 2;
            color: black;
        }

        .welcome-content .entry-content h6 {
            font: 25px;
            font-weight: bold;
            color: black;
        }
        .welcome-content .entry-content .list {
            font-size: 20px;
            line-height: 4;
            color: black;
        }

        .home-page img {
            display: block;
            width: 100%;
        }

        @media screen and (max-width: 992px) {
            .home-page img {
                margin-bottom: 60px;
            }
        }
        /*

*/

 .contentt {
    position: relative;
    padding: 96px 0;
    background-color: #dbd9dc;
    color: #212e34;
}

.contentt::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #dbd9dc;
}

.welcome-contentt .entry-title {
    position: relative;
    padding-bottom: 24px;
    padding-top: 22px;
    font-size: 36px;
    font-weight: 600;
    color: #212e34;
}

.welcome-contentt .entry-title::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 64px;
    height: 4px;
    background: #212e34;
}

.welcome-content .entry-content {
    font-size: 14px;
    line-height: 2;
    color: white;
}

.contentt video {
    display: block;
    width: 100%;
    outline-color: inherit;
}

@media screen and (max-width: 992px) {
    .contentt img {
        margin-bottom: 60px;
    }
}



/*--------------------------------------------------------------
# Mortgage
--------------------------------------------------------------*/

.mort-page {
    padding: 0 0 96px;
}



.mort-page .entry-content {
    margin-top: 96px;

}

.mort-page .entry-content h3 {
    position: relative;
    padding-bottom: 24px;
    font: 25px;
    font-weight: bold;
    color: black;
}
.mort-page .entry-content p {
  padding-top: 15px;
  font-size: 16px;
  line-height: 2;
  color: black;
}
.mort-page .entry-content h2 {
    position: relative;
    padding-bottom: 24px;
    padding-top: 40px;
    font-size: 36px;
    font-weight: 600;
    color: #6bb0e8;
}

.mort-form {
    padding: 35px 20px 55px;
    margin-top: 96px;
    background: #edf3f5;
}


/*
same styling as mort-form to mortgage calculator
*/


.mort-form input[type="number"]{
    width: 100%;
    padding: 12px 24px;
    margin-bottom: 18px;
    border: 1px solid #e0e0e0;
    font-size: 14px;
    line-height: 1;
}


.mort-form input[type="number"]:focus {
    outline: none;
}

.mort-form input[type="number"]::placeholder{
    color: #929191;
}
.mort-form input[name="princple"],
.mort-form input[name="down"],
.mort-form input[name="time"],
.mort-form input[name="rate"],
label {
    width:49%;
    margin-left: 5px;

}

.mort-label{
  display: none;
}
@media screen and (max-width: 770px) {
  .mort-form input[name="princple"],
  .mort-form input[name="down"],
  .mort-form input[name="time"],
  .mort-form input[name="rate"],
  label {
      width:100%;
      margin-left: 5px;

  }
  .mort-label{
    display:block;
  }
  .mort-unlabel{
    display: none;
  }
}

@media screen and (max-width: 1200px) and (min-width:990px ) {
  .mort-form input[name="princple"],
  .mort-form input[name="down"],
  .mort-form input[name="time"],
  .mort-form input[name="rate"],
  label {
      width:100%;
      margin-left: 5px;

  }
  .mort-label{
    display:block;
  }
  .mort-unlabel{
    display: none;
  }
}

.mort-form p {
  font-size: 13px;
}


.mort-form span {
    display: block;
    margin-top: 16px;
    text-align: center;
}

.mort-form input[type="submit"] {
  display: inline-block;
  padding: 20px 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #000;
  border-radius: 12px;
  text-decoration: navajowhite;
  background:  white;
}

.mort-form input[type="submit"]:hover{
  background: #6bb0e8;
}
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.contact-page {
    padding: 0 0 96px;
    background: #edf3f5;
}



.contact-page .entry-content {
    margin-top: 96px;

}

.contact-page .entry-content h3 {
    font-size: 24px;
    font-weight: 500;
    position: relative;
    padding-bottom: 24px;
    font-size: 36px;
    font-weight: 600;
    color: #212e34;
}


.contact-page .entry-content p {
    margin-top: 20px;
    font-size: 14px;
    color: #929191;
}

.contact-page .entry-content ul.contact-social {
    padding: 20px 0 0;
    margin: 0;
    list-style: none;
}

.contact-page .entry-content ul.contact-social li {
    margin-right: 28px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: bold;
}

.contact-page .entry-content ul.contact-social li a {
    color: #6bb0e8;
    font-size: 24px;
    font-weight: bold;
}

.contact-page .entry-content ul.contact-info {
    margin: 32px 0 0;
}

.contact-page .entry-content ul.contact-info li {
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;
    font-size: 14px;
    color: #6bb0e8;
}
.contact-info a {
  color: #6bb0e8;
  font-size: 18px;
  font-weight: bold;
  align-items: baseline;
}

.contact-page .entry-content ul.contact-info .fa {
    width: 28px;
    margin-right: 10px;
    font-size: 25px;
    font-weight: bold;
    color: #207ce5;
    text-align: center;
}

.contact-form {
    padding: 35px 20px 55px;
    margin-top: 96px;
    background: #fff;
}


/*
same styling as contact-form to mortgage calculator
*/

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="tel"],
.contact-form input[type="number"],
.contact-form textarea {
    width: 100%;
    padding: 12px 24px;
    margin-bottom: 18px;
    border: 1px solid #e0e0e0;
    font-size: 14px;
    line-height: 1;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form input[type="tel"]:focus,
.contact-form input[type="number"]:focus,
.contact-form textarea:focus {
    outline: none;
}

.contact-form input[type="text"]::placeholder,
.contact-form input[type="email"]::placeholder,
.contact-form input[type="tel"]::placeholder,
.contact-form input[type="number"]::placeholder,
.contact-form textarea::placeholder {
    color: #929191;
}



.contact-form span {
    display: block;
    margin-top: 16px;
    text-align: center;
}

.contact-form input[type="submit"] {
  display: inline-block;
  padding: 20px 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #000;
  border-radius: 12px;
  text-decoration: navajowhite;
  background: #edf3f5;
}

.contact-form input[type="submit"]:hover{
  background: #6bb0e8;
}

.left-panel {
    display: inline-block;
    width: 30%;
    height: 125px
}

.right-panel {
    display: inline-block;
    width: 70%;
    padding-top: 0px;
    padding-bottom: 0px
}
.right-panel p {
  position: relative;
  bottom: 35px;
}
.right-panel ul {
  position: relative;
  bottom: 55px;
}
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: rgba(38,66,77,255);
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .copyright {
  border-top: 1px solid #262626;
  text-align: center;
  padding-top: 30px;
}
#footer  p{
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
#footer a {
  color: #6bb0e8;
}
@media (max-width: 575px) {
  #footer .footer-top .footer-info {
    margin: -20px 0 30px 0;
  }
}
